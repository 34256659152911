<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="cart.count"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Sepetim') }}
        </h4>
        <b-badge
          v-if="cart.count"
          pill
          variant="light-primary"
        >
          {{ cart.count }} {{ $t('Adet') }}
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="cart.count"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in cart.items"
        :key="item.id"
      >
        <template #aside>
          <b-avatar
            :text="item.avatar_text"
            variant="primary"
            size="36"
          />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click="removeItem(item.id)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title mb-0">
            <b-link class="text-body">
              {{ item.package }}
            </b-link>
          </h6>
          <small class="cart-item-by">{{ item.startup }}</small>
        </div>
        <h5 class="cart-item-price">
          <div>{{ item.amount | toCurrency }} ₺</div>
          <small class="text-muted">+ KDV</small>
        </h5>
      </b-media>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li
      v-if="cart.count"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          {{ $t('Toplam') }}:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          <div>{{ cart.total | toCurrency }} ₺</div>
          <small class="text-muted">+ KDV</small>
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        to="/siparislerim/sepet"
      >
        {{ $t('Siparişi Tamamla') }}
      </b-button>
    </li>
    <p
      v-if="!cart.count"
      class="m-0 p-1 text-center"
    >
      {{ $t('Sepetiniz Boş') }}
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    cart() {
      return this.$store.getters['cart/getCart']
    },
  },
  created() {
    this.getCarts()
  },
  methods: {
    getCarts() {
      this.$store.dispatch('cart/cartData')
    },
    removeItem(id) {
      this.$store.dispatch('cart/cartRemoveItem', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
