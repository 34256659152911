<template>
  <div>
    <b-row>
      <b-col
        xs="12"
        md="6"
        class="mb-1 mt-1"
      >
        <b-dropdown
          id="activity_area"
          ref="dropdown"
          right
          variant="outline-primary"
          :text="$t('Faaliyet Alanı')"
          block
          menu-class="w-100"
        >
          <b-dropdown-form
            class="py-1"
            style="height: 250px; overflow:scroll"
          >
            <b-form-checkbox
              v-for="(activity_area, key) in activityAreas"
              :key="key"
              v-model="filterData.id_activity_areas"
              class="mb-1"
              :value="activity_area.id"
              switch
            >
              {{ activity_area.title }}
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
      <b-col
        xs="12"
        md="6"
        class="mt-1"
      >
        <b-dropdown
          id="marketing_focus"
          ref="dropdown"
          right
          variant="outline-success"
          :text="$t('Pazarlama Odağı')"
          block
          menu-class="w-100"
        >
          <b-dropdown-form
            class="py-1"
            style="height: 150px; overflow:scroll"
          >
            <b-form-checkbox
              v-for="(marketing_focus, mKey) in marketingFocus"
              :key="mKey"
              v-model="filterData.id_marketing_focus"
              class="mb-1"
              :value="marketing_focus.id"
              switch
            >
              {{ marketing_focus.title }}
            </b-form-checkbox>
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BDropdown, BDropdownForm, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'StartupFilter',
  components: {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownForm,
    BFormCheckbox,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    actionMethods: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    actionFilter: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    resetFilter: {
      type: Function,
    },
  },
  data() {
    return {
      keyword: null,
      detailSearch: false,
    }
  },
  computed: {
    activityAreas() {
      return this.$store.getters['appActivityAreas/getActivityAreas']
    },
    marketingFocus() {
      return this.$store.getters['appMarketingFocus/getMarketingFocuses']
    },
    filterData() {
      return this.$store.getters['startups/getStartupFilterData']
    },
  },
  created() {
    this.getActivityAreas()
    this.getMarketingFocus()
  },
  methods: {
    getMarketingFocus() {
      this.$store.dispatch('appMarketingFocus/marketingFocusesList')
    },
    getActivityAreas() {
      this.$store.dispatch('appActivityAreas/activityAreasList')
    },
  },
}
</script>
