<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="noticesCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Bildirimler') }}
        </h4>
        <b-badge
          v-if="noticesCount > 0"
          pill
          variant="light-primary"
        >
          {{ noticesCount }} Yeni
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="noticesCount > 0"
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <div
        v-for="notice in notices"
        :key="notice.id"
      >
        <b-link>
          <b-media>
            <template #aside>
              <b-avatar variant="light-primary">
                <feather-icon icon="BellIcon" />
              </b-avatar>
            </template>
            <b-row>
              <b-col>
                <p class="media-heading">
                  <span class="font-weight-bolder">
                    {{ notice.notice_type }}
                  </span>
                </p>
                <small class="notification-text">{{ notice.text }}</small>
              </b-col>
              <b-col cols="auto">
                <b-button
                  size="sm"
                  variant="flat-primary"
                  class="btn-icon"
                  @click="readStatus(notice.id)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-media>
        </b-link>
      </div>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <li
      v-else
      class="dropdown-menu-footer"
    >
      <div class="text-center">
        Hiç yeni bildiriminiz yok!
      </div>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BRow, BCol,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BRow,
    BCol,
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    notices() {
      return this.$store.getters['notices/getNotices']
    },
    noticesCount() {
      return this.$store.getters['notices/getNoticesCount']
    },
    noticeSaveStatus() {
      return this.$store.getters['notices/getNoticeSaveStatus']
    },
  },
  watch: {
    noticeSaveStatus(val) {
      if (val.status) {
        this.getNotices()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'İşlem Tamam!',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Bildiriminiz okundu olarak işaretlendi!',
          },
        })
      }
    },
  },
  created() {
    this.getNotices()
  },
  methods: {
    getNotices() {
      this.$store.dispatch('notices/notices')
    },
    readStatus(id) {
      this.$store.dispatch('notices/readNotice', id)
    },
  },
}
</script>

<style>

</style>
